import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from "moment"
import LeavePopup from "./../components/leavePopup"
// import { TwitterTweetEmbed } from 'react-twitter-embed';

const News = ({ data }) => {
  let pressrelease

  const [leavePopup, setLeavePopup] = useState({ visible: false, link: "" })

  const hideLeavePopup = () => {
    setLeavePopup(current => ({ ...current, visible: false }))
  }

  data.wpgraphql.newsmediaplural.edges.filter(item => {
    return (
      item.node.name === "pressrelease" &&
      (pressrelease = item.node.newsMedia.edges)
    )
  })

  return (
    <Layout>
      <Seo title={"Pressrelease"} description={"All pressrelease list"} />
      <section className="container py-5">
        {/* <div className="row">
          <div className="col-12 mx-auto text-center pt-3 pb-4 mb-4">
            <h1 className="pb-2">EnerBank USA in the News</h1>
            <p className="mb-5">
              <span className="text-blue">Press/Media Contact Info:</span>
              <br />SnappConner PR<br /> 801.806.0150<br />
              <span className="text-blue">info@snappconner.com</span>
            </p>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="font-weight-normal pb-4">
              <Link to={"/news/"}> &#60; Back to News main page</Link>
            </div>
            <div className="d-block">
              <h1 className="pb-2 mr-auto">
                EnerBank USA in the Press Releases
              </h1>
            </div>
            <hr className="mb-4" />
          </div>
        </div>
        {pressrelease.map((item, i) => {
          return i !== 3 && i > 3 ? (
            <div className="row">
              <div
                className="col-12 my-3 blog-post border-bottom pb-4"
                key={item.node.id + i}
              >
                <div className="d-lg-flex">
                  <h4 className="mb-2">{item.node.title}</h4>
                  <h5
                    className="mb-2 ml-auto font-weight-normal text-lg-right"
                    style={{ minWidth: "150px" }}
                  >
                    {moment(item.node.date).format("MMMM D, Y")}
                  </h5>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                  className="mb-auto"
                />
                <a
                  href={
                    item?.node?.newsmedia?.newslink?.url ??
                    "/pressrelease/" + item?.node?.slug + "/"
                  }
                  rel="noreferrer"
                  className="btn-link p-0"
                >
                  Read more
                </a>
              </div>
            </div>
          ) : null
        })}
        <LeavePopup toggle={leavePopup} onToggle={hideLeavePopup} />
      </section>

      {/* <section className="container py-5">
        <div className="row">
          <h2 className="h2 mb-5 col-12">EnerBank on Social Media</h2>
        </div>
        <div className="row justify-content-between px-3 social-card">
          {
            data.allTwitterStatusesUserTimelineHashtagGatsby.edges.map((tweet, i) =>
            (tweet &&
              <TwitterTweetEmbed tweetId={tweet.node.id_str} key={tweet.node.id + i} />
            ))
          }
        </div>
      </section> */}
    </Layout>
  )
}
export default News
// allTwitterStatusesUserTimelineHashtagGatsby {
//   edges {
//     node {
//       id
//       id_str
//     }
//   }
// }
export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
      }

      newsmediaplural {
        edges {
          node {
            id
            name
            newsMedia(where: { orderby: { order: DESC, field: DATE } }) {
              edges {
                node {
                  title
                  date
                  excerpt
                  slug
                  newsmedia {
                    fieldGroupName
                    newslink {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
